import React, { Component } from 'react';
import { withI18n } from '@lingui/react';
import { connect } from 'react-redux';

import Tooltip from './utils/Tooltip';
import { AddToCollectionBtn } from './AddToCollectionModal';
import { selectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { DeleteArticleBtn } from './DeleteArticleBtn';
// import Swal from 'sweetalert2';
export class CollectionControlsHeader extends Component {
    // handleDelete = (e) => {
    //     e.stopPropagation();

    //     const { i18n } = this.props;

    //     Swal.fire({
    //         icon: 'warning',
    //         text: i18n._('collection:confirm:remove:all:page:articles'),
    //         showCancelButton: true,
    //         confirmButtonText: i18n._('confirm'),
    //         confirmButtonColor: '#007bff',
    //         cancelButtonText: i18n._('cancel'),
    //     }).then((res) => {
    //         if (res.value === true) {
    //             this.props.onRemoveAll();
    //         }
    //     });
    // };

    render() {
        const {
            /*selectAllDisabled, enableAddItemsModal, */ onSelectAll,
            selectAllChecked,
            i18n,
            showLists,
            deleteProductsGrant,
        } = this.props;

        return (
            <span className="flex items-center pt-0.5">
                <Tooltip content={i18n._('Add all page articles')} position="top">
                    <label
                        className="checkmark-container"
                        onClick={(e) => {
                            e.stopPropagation();
                            onSelectAll();
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {}}
                        />
                        <span className="checkmark"></span>
                    </label>
                </Tooltip>
                {/* <span>
                    <Tooltip content={i18n._('Add all page articles')} position="top">
                        <label
                            className="form-checkbox form-checkbox-inline float-left"
                            // data-tooltip={i18n._('Add all page articles')}
                            style={{ top: 0, paddingRight: 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                // if (selectAllDisabled === false) {
                                onSelectAll();
                                // }
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={selectAllChecked}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => {}}
                                // disabled={selectAllDisabled}
                            />
                            <i className="form-icon" />
                        </label>
                    </Tooltip>
                </span> */}
                {showLists && <AddToCollectionBtn />}
                {deleteProductsGrant && <DeleteArticleBtn />}
                {/* <Tooltip
                    content={i18n._('Remove all page articles from the collection')}
                    position="top"
                >
                    <i
                        className="icon icon-cross text-error c-hand"
                        onClick={this.handleDelete}
                    />
                </Tooltip> */}
            </span>
        );
    }
}

function mapStateToProps(state) {
    return {
        deleteProductsGrant: selectors.getUserGrant(state, 'delete_products'),
        showLists: appSelectors.getShowLists(state),
    };
}

const ConnectedCollectionControlsHeader = connect(mapStateToProps)(CollectionControlsHeader);

export default withI18n()(ConnectedCollectionControlsHeader);
