import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import cx from 'classnames';

import RowCompact from './RowCompact';
import RowDetailed from './RowDetailed';
import ArticleDetailsModal from './ArticleDetailsModal';
// import ArticleDetails from '../containers/ArticleDetails';
import { VIEW_TYPE_COMPACT, VIEW_TYPE_DETAILED, VIEW_TYPE_BLOCK } from '../constants';

function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

class Row extends Component {
    state = {
        showDetails: false,
    };

    toggleDetails = () => {
        this.setState((prevState) => {
            return {
                showDetails: !prevState.showDetails,
            };
        });
    };

    showDetails = () => {
        this.setState({
            showDetails: true,
        });
    };

    hideDetails = () => {
        this.setState({
            showDetails: false,
        });
    };

    getRowComponent() {
        switch (this.props.type) {
            case VIEW_TYPE_DETAILED:
                return RowDetailed;
            case VIEW_TYPE_COMPACT:
            default:
                return RowCompact;
        }
    }

    renderRow() {
        const {
            item,
            index,
            q,
            language,
            productProperties,
            availableProps,
            showLists,
            priceDecimalPrecision,
        } = this.props;
        const { showDetails } = this.state;

        const odd = isOdd(index);

        const trClass = cx('c-zoom-in', {
            // 'c-zoom-in': !showDetails,
            // 'article-row-expanded': showDetails,
            'bg-alternate-row': !odd,
        });

        const RowComponent = this.getRowComponent();

        const rowProper = (
            <RowComponent
                // bg={odd ? 'bg-white' : 'bg-alternate-row'}
                // showDetails={showDetails}
                item={item}
                language={language}
                // onCloseButtonClick={this.hideDetails}
                q={q}
                // selectable={this.props.selectable}
                onSelect={this.props.onSelectRow}
                productProperties={productProperties}
                availableProps={availableProps}
                showLists={showLists}
                priceDecimalPrecision={priceDecimalPrecision}
                deleteProductsGrant={this.props.deleteProductsGrant}
            />
        );

        // if (this.props.type !== VIEW_TYPE_BLOCK) {
        return (
            <tr className={trClass} onClick={showDetails ? () => {} : this.showDetails}>
                {rowProper}
            </tr>
        );
        // }

        // return rowProper;
    }

    render() {
        const { item, /*index,*/ type } = this.props;
        const { showDetails } = this.state;

        if (type === VIEW_TYPE_BLOCK) {
            return null;
        }

        // const odd = isOdd(index);

        return (
            <Fragment>
                {this.renderRow()}
                {showDetails && (
                    <Portal>
                        <ArticleDetailsModal
                            article={item}
                            onClose={this.hideDetails}
                            containerId={`article-detail-modal-${item.id}`}
                        />
                    </Portal>
                )}
                {/* <ArticleDetails
                    item={item}
                    open={showDetails}
                    // tdClass={odd ? '' : 'bg-alternate-row'}
                /> */}
            </Fragment>
        );
    }
}

Row.propTypes = {
    index: PropTypes.number,
    item: PropTypes.object,
    onSelectRow: PropTypes.func,
    type: PropTypes.string,
    showLists: PropTypes.bool,
    // selectable: PropTypes.bool,
    productProperties: PropTypes.object,
    deleteProductsGrant: PropTypes.object,
};

export default Row;
