// import matchSorter from 'match-sorter';
// import memProfile from 'memoizee/profile';
// import memoizee from 'memoizee';
import filter from 'lodash/filter';
import transform from 'lodash/transform';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import { normalize, schema } from 'normalizr';

import * as c from './constants';
import { /*fetchItem, fetchEtimClassDetails,*/ fetchItem } from './api';

// function matchAndSortFn(items, value, keys = ['label']) {
//     return value
//         ? matchSorter(items, value, {
//               keys,
//               threshold: matchSorter.rankings.CONTAINS
//           })
//         : items;
// }

// export const matchAndSort = memoizee(matchAndSortFn, { max: 10 });

const assetClasses = new schema.Entity(
    'asset_classes',
    {},
    {
        idAttribute: 'code',
    }
);

const asset = new schema.Entity(
    'asset',
    {
        asset_classes: [assetClasses],
    },
    {
        idAttribute: 'filename',
    }
);

const assetsCollection = new schema.Array(asset);

function filterAssetsCollection(collection, key) {
    return collection.map((a) => {
        a.asset_classes = filter(a.asset_classes, { asset_type: key });

        return a;
    });
}

// export function normalizeArticleDetails(data) {
//     return {
//         ...data,
//         assets: transform(
//             data.assets,
//             (assets, v, k) => {
//                 assets[k] = normalizeAssetsCollection(v, k);

//                 return assets;
//             },
//             {}
//         )
//     };
// }

export const normalizeAssetsCollection = (collection, key) => {
    const filteredCollection = filterAssetsCollection(collection, key);

    return normalize(filteredCollection, assetsCollection);
};

export const removeSplashScreenLoader = () => {
    const loaderNode = document.getElementById('app-loader');

    if (loaderNode) {
        loaderNode.remove();
    }
};

const categories = Object.values(c.ARTICLE_DETAIL_SECTIONS);

export function getCategoryButtonStatus(details) {
    const base = categories.reduce((obj, category) => {
        obj[category.key] = true;

        return obj;
    }, {});

    if (!isArray(details.articoli_correlati) || details.articoli_correlati.length === 0) {
        base[c.SECTION_KEY_CORRELATI] = false;
    }

    if (details.confezionamenti.length === 0) {
        base[c.SECTION_KEY_PACKAGING] = false;
    }

    if (!details.prezzi || details.prezzi.length === 0) {
        base[c.SECTION_KEY_PRICING] = false;
    }

    if (!details.listini || Object.keys(details.listini).length === 0) {
        base[c.SECTION_KEY_LISTINI] = false;
    }

    if (!details.assets || !details.assets.altro || details.assets.altro.length === 0) {
        base[c.SECTION_KEY_ALTRO] = false;
    }

    if (!details.assets || !details.assets.disegno || details.assets.disegno.length === 0) {
        base[c.SECTION_KEY_DISEGNI] = false;
    }

    if (!details.assets || !details.assets.documento || details.assets.documento.length === 0) {
        base[c.SECTION_KEY_DOCUMENTI] = false;
    }

    if (!details.assets || !details.assets.immagine || details.assets.immagine.length === 0) {
        base[c.SECTION_KEY_IMMAGINI] = false;
    }

    if (!details.assets || !details.assets.vettoriale || details.assets.vettoriale.length === 0) {
        base[c.SECTION_KEY_VETTORIALI] = false;
    }

    if (!details.assets || !details.assets.videoclip || details.assets.videoclip.length === 0) {
        base[c.SECTION_KEY_VIDEO] = false;
    }

    if (!details.etim || !details.etim.etim_features || details.etim.id === null) {
        base[c.SECTION_KEY_CARATTERISTICHE] = false;
    }

    if (!details.identificazione) {
        base[c.SECTION_KEY_ID] = false;
    }

    if (!details.dettagli_ordine) {
        base[c.SECTION_KEY_ORDINE] = false;
    }

    if (!details.aggregazioni) {
        base[c.SECTION_KEY_AGGREGAZIONI] = false;
    } else {
        const notNullAggregazioni = transform(
            details.aggregazioni,
            (result, item) => {
                if (item.value !== null) {
                    result.tot += 1;
                }
            },
            { tot: 0 }
        );

        if (notNullAggregazioni.tot === 0) {
            base[c.SECTION_KEY_AGGREGAZIONI] = false;
        }
    }

    if (!details.varie) {
        base[c.SECTION_KEY_VARIE] = false;
    } else {
        const notNullVarie = transform(
            details.varie,
            (result, item) => {
                if (item.value !== null) {
                    result.tot += 1;
                }
            },
            { tot: 0 }
        );

        if (notNullVarie.tot === 0) {
            base[c.SECTION_KEY_VARIE] = false;
        }
    }

    // console.warn(details.misure);

    if (!details.misure) {
        base[c.SECTION_KEY_MISURE] = false;
    } else {
        const misure = transform(
            details.misure,
            (result, obj) => {
                if (obj.value !== null) {
                    result.push(obj.value);
                }

                return result;
            },
            []
        );

        if (misure.length === 0) {
            base[c.SECTION_KEY_MISURE] = false;
        }
    }

    const categoryKeys = categories.map((c) => c.key);

    forOwn(details, (v, k) => {
        if (!includes(categoryKeys, k) && !includes(c.EXTRA_CATEGORIES_BLACKLIST_KEYS, k)) {
            if (Object.keys(v).length > 0) {
                const values = transform(
                    v,
                    (result, obj) => {
                        // console.warn(obj);
                        if (obj.value !== null || obj.children.length > 0) {
                            result.push(obj.value);
                        }

                        return result;
                    },
                    []
                );

                if (values.length === 0) {
                    base[k] = false;
                } else {
                    base[k] = true;
                }
            }
        }
    });

    return base;
}

// export async function fetchItemDetails(id) {
//     const { data } = await fetchItem(id);

//     let etimClassDetails = null;
//     const articleDetails = normalizeArticleDetails(data);

//     if (data.aggregazioni.etim_class.value !== null) {
//         // TODO: tenere una cache di questi risultati
//         const etimClassDetailsRes = await fetchEtimClassDetails(
//             data.aggregazioni.etim_class.value.etim_class_id
//         );

//         etimClassDetails = etimClassDetailsRes.data;
//     }

//     // console.info(articleDetails);
//     // console.info(etimClassDetails);

//     return {
//         etimClassDetails,
//         articleDetails
//     };
// }

export function normalizeArticleDetails(data) {
    const repeatables = ['prezzi', 'confezionamenti', 'listini'];

    let details = {
        id: data.id,
        metadata: data.metadata || {},
    };

    // console.warn(data);

    data.children.forEach((category) => {
        details[category.property] = category.children.reduce((obj, property) => {
            if (includes(repeatables, category.property)) {
                if (!isArray(obj)) {
                    obj = [];
                }

                obj.push(
                    property.children.reduce((propObj, p) => {
                        propObj[p.property] = p;

                        return propObj;
                    }, {})
                );
            } else if (category.property === 'assets') {
                const type = get(property, 'value.asset_classes[0].asset_type', 'altro');

                if (!obj[type]) {
                    obj[type] = [];
                }

                obj[type].push(property.value);
            } else if (category.property === 'etim') {
                if (property.property === 'etim_class') {
                    if (property.value) {
                        obj.id = property.value.id;
                        obj.name = property.value.name;
                        obj.label = property.value.label;
                    } else {
                        // FIXME: tradurre
                        obj.id = null;
                        obj.name = 'Non categorizzato';
                        obj.label = 'Non categorizzato';
                    }
                } else if (property.property === 'etim_features') {
                    obj.etim_features = property.children.reduce((featObj, f) => {
                        featObj[f.value.id] = f.value;

                        return featObj;
                    }, {});
                }
            } else if (category.property === c.SECTION_KEY_CORRELATI) {
                if (!isArray(obj)) {
                    obj = [];
                }

                obj.push(property.value);
            } else {
                obj[property.property] = property;
            }

            return obj;
        }, {});
    });

    repeatables.forEach((key) => {
        if (!isArray(details[key])) {
            details[key] = [];
        }
    });

    // console.log(details);

    return details;
}

export async function fetchItemDetails(id) {
    const { data } = await fetchItem(id);

    const articleDetails = normalizeArticleDetails(data);

    // console.log(articleDetails);

    return articleDetails;
}

export function getDescrizioneMarcaFromArticolo(article, locale) {
    return (
        get(article, 'identificazione.descrizione_marca.value') ||
        get(article, `identificazione.sigla_marca.value.label.${locale}`) ||
        get(article, 'identificazione.sigla_marca.value.code')
    );
}

// export const profiler = memProfile;
