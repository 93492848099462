import arrify from 'arrify';

export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE';
export const ENABLE_FILTERS = 'ENABLE_FILTERS';
export const DISABLE_FILTERS = 'DISABLE_FILTERS';
export const CHANGE_BRAND_FILTER_VALUE = 'CHANGE_BRAND_FILTER_VALUE';
export const RESET_FILTERS = 'RESET_FILTERS';

export function setFilterValue(key, value, extra = {}) {
    return {
        type: SET_FILTER_VALUE,
        payload: {
            key,
            value,
            extra,
        },
    };
}

export function setFilterOptions(key, options) {
    return {
        type: SET_FILTER_OPTIONS,
        payload: {
            key,
            options,
        },
    };
}

export function enableFilters(keys) {
    return {
        type: ENABLE_FILTERS,
        payload: arrify(keys),
    };
}

export function disableFilters(keys) {
    return {
        type: DISABLE_FILTERS,
        payload: arrify(keys),
    };
}

export function changeBrandFilterValue(value) {
    return {
        type: CHANGE_BRAND_FILTER_VALUE,
        payload: value,
    };
}

export function resetFilters(keys) {
    return {
        type: RESET_FILTERS,
        payload: arrify(keys),
    };
}

export function setPriceRange(range) {
    return {
        type: SET_PRICE_RANGE,
        payload: range,
    };
}

export const actionTypes = {
    SET_FILTER_VALUE,
    SET_FILTER_OPTIONS,
    ENABLE_FILTERS,
    DISABLE_FILTERS,
    CHANGE_BRAND_FILTER_VALUE,
    RESET_FILTERS,
    SET_PRICE_RANGE,
};

export const actions = {
    setFilterValue,
    setFilterOptions,
    enableFilters,
    disableFilters,
    changeBrandFilterValue,
    resetFilters,
    setPriceRange,
};
