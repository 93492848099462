import React, { Component } from 'react';
import { connect } from 'react-redux';

import CollectionsList from '../components/CollectionsList';
import { selectors } from '../reducers/collectionsReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { actions } from '../actions/collectionsActions';
import { actions as appActions } from '../actions/appActions';

export class CollectionsListContainer extends Component {
    render() {
        return <CollectionsList {...this.props} />;
    }
}

function mapStateToProps(state) {
    return {
        isFetching: selectors.getIsFetching(state),
        collections: selectors.getDisplayItems(state),
        total: selectors.getFilteredItemsTotal(state),
        total_unfiltered: selectors.getItemsTotal(state),
        currentPage: selectors.getCurrentPage(state),
        pages: selectors.getPagesTotal(state),
        sorting: selectors.getSorting(state),
        collectionEngagedId: selectors.getEngagedCollection(state),
        collectionShown: selectors.getShownCollection(state),
        filters: selectors.getFilters(state),
        isSidebarOpen: appSelectors.getIsSidebarOpen(state),
        enableExportPanel: appSelectors.getEnableExportPanel(state),
        enableExportLists: appSelectors.getEnableExportLists(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCollections() {
            dispatch(actions.fetchCollections());
        },
        toggleEngageCollection(id) {
            dispatch(actions.toggleEngageCollection(id));
        },
        toggleShownCollection(id) {
            dispatch(actions.toggleShownCollection(id));
        },
        dupeCollection(id) {
            dispatch(actions.dupeCollection(id));
        },
        changePage(page) {
            dispatch(actions.changePage(page));
        },
        sortBy(attr) {
            dispatch(actions.sortCollectionsBy(attr));
        },
        deleteCollection(id) {
            dispatch(actions.deleteCollection(id));
        },
        updateCollectionFilter(key, value) {
            dispatch(actions.updateCollectionFilter(key, value));
        },
        toggleSidebar() {
            dispatch(appActions.toggleSidebar());
        },
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsListContainer);
