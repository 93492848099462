import axios from 'axios';
import queryString from 'query-string';
import has from 'lodash/has';
import get from 'lodash/get';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import arrify from 'arrify';

import noPhotoImg from './images/no-photo.png';

// import * as constants from './constants';

// const clientFake = axios.create({
//     baseURL: process.env.REACT_APP_FAKE_API_ROOT,
//     paramsSerializer(params) {
//         return queryString.stringify(params, { arrayFormat: 'bracket' });
//     }
// });

const etimClassesCache = {};

// const client = axios.create({
//     // baseURL: process.env.REACT_APP_API_ROOT,
//     paramsSerializer(params) {
//         return queryString.stringify(params, { arrayFormat: 'bracket' });
//     }
// });

export const client = axios.create({
    timeout: 30000,
    // baseURL: 'https://stage-dev.ildatapool.idrolab.net/app_dev.php/v2',
    // baseURL: 'https://stage-dev.ildatapool.idrolab.net/v2',
    paramsSerializer(params) {
        return queryString.stringify(params, { arrayFormat: 'bracket' });
    },
});

export function setBaseApiURL(url) {
    client.defaults.baseURL = url;
}

let baseAssetsURL = null;
let assetsThumbURL = null;
let assetsEtimURL = null;

export function setBaseAssetsURL(url) {
    baseAssetsURL = url;
}

export function setAssetsThumbURL(url) {
    assetsThumbURL = url;
}

export function setAssetsEtimURL(url) {
    assetsEtimURL = url;
}

export function getAssetURL(path) {
    const regex = /^https?:\/\//i;

    if (regex.test(decodeURIComponent(path))) {
        return decodeURIComponent(path);
    }

    return `${baseAssetsURL}/${path}`;
}

export function getAssetThumbUrl(fileName) {
    return `${assetsThumbURL}/${fileName}.jpg`;
}

export function getEtimImageUrl(name) {
    return `${baseAssetsURL}/XXX/${name}.jpg`;
    // return `${assetsEtimURL}/${name}.jpg`;
}

export function getProductImagePath(item, thumb = false) {
    // console.log(item);
    if (item.metadata && item.metadata.immagine_prodotto && item.metadata.immagine_prodotto.url) {
        const imgPath = thumb
            ? getAssetThumbUrl(item.metadata.immagine_prodotto.url)
            : getAssetURL(item.metadata.immagine_prodotto.url);
        return [imgPath, noPhotoImg];
    }

    const etimClass = find(item.children, { property: 'etim_class' });

    if (etimClass && etimClass.value && etimClass.value.name) {
        return [getEtimImageUrl(etimClass.value.name), noPhotoImg];
    }

    if (item.etim && item.etim.name) {
        return [getEtimImageUrl(item.etim.name), noPhotoImg];
    }

    return noPhotoImg;
}

const pathRe = /^\/app_dev.php/;

export function getBaseUrlFromLocation(location) {
    const re = /.*\/$/;

    const endsWithSlash = re.test(location.pathname);

    const ensuredSlash = endsWithSlash ? '' : '/';

    const pathname = pathRe.test(location.pathname) ? '/app_dev.php' : endsWithSlash ? '/' : '';

    return `${location.origin}${pathname}${ensuredSlash}v2`;
}

export function getBasePathFromLocation(location) {
    let basePath = '/dp';

    if (pathRe.test(location.pathname)) {
        basePath = `/app_dev.php${basePath}`;
    }

    return basePath;
}

export function getAssetUrlFromLocation() {
    return `${window.location.origin}/assets`;
}

export function setToken(token) {
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // clientV2.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function setClientTimeout(seconds) {
    client.defaults.timeout = seconds * 1000;
}

// export function getProductInfoMap() {
//     return client.get('map/articolo');
// }

export function fetchBrandFilter(params = {}) {
    return client.post('filter/marche', params);
}

export function fetchBrands(_, params = {}) {
    return client.post('filter/marche', params);
}

export function fetchStatusFilter(params = {}) {
    return client.post('filter/stati_articolo', params);
}

export function fetchProductTreeRootsFilter(params = {}) {
    return client.post('filter/product_tree_roots', params);
}

export function fetchModelliFilter(params = {}) {
    return client.post('filter/modelli', params);
}

export function fetchLineeFilter(params = {}) {
    return client.post('filter/linee', params);
}

export function fetchSeriesFilter(params = {}) {
    return client.post('filter/series', params);
}

export function fetchClassificationTree(params = {}) {
    return client.post('filter/product_tree', params);
}

export function fetchEtimClassFilter(params = {}) {
    return client.post('filter/etim_classes', params);
}

export function fetchPriceRangeFilter(params = {}) {
    return client.post('/filter/prezzi_listino', params);
}

export function fetchListini(params = {}) {
    return client.post('/filter/listini', params);
}

export function fetchEtimFeatures(params = {}) {
    return client.post('filter/etim_features', params);
}

export function fetchEtimFeatureValues(id, params = {}) {
    return client.post(`filter/etim_features/${id}/values`, params);
}

export function fetchEtimClassDetails(id) {
    if (has(etimClassesCache, id)) {
        return new Promise((resolve) => {
            resolve(etimClassesCache[id]);
        });
    }

    const response = client.get(`etim/etim_class/${id}`);

    response.then(({ data }) => {
        // TODO: controllo sufficente???
        if (data !== null) {
            etimClassesCache[data.id] = { data };
        }
    });

    return response;
}

export function fetchItems(payload = {}) {
    const params = {
        ...payload,
    };

    return client.post('/products', params);
}

export function fetchItemsCustom(_, payload = {}, properties, isAnyFilterActive) {
    // Aggiungo sempre proprieta radici dei prezzi, serie e linee altrimenti non ritornano risultati per le foglie
    properties.unshift('prezzi', 'prezzo', 'serie', 'linee');

    properties = uniq(properties);

    const params = {
        ...payload,
        properties,
        // property_format: 'code'
    };

    if (isAnyFilterActive === false) {
        return Promise.resolve({ data: { results: [] } });
    }

    return new Promise(async (resolve, reject) => {
        const res = await client.post('/products_custom?property_format=code', params);

        // console.log(res);

        // Normalizzo proprieta dei prezzi che si trovano nestate dentro array dei prezzi
        if (res.data && res.data.results) {
            res.data.results = res.data.results.map((item) => {
                const prezzi = find(item.children, { property: 'prezzi' });

                const priceProps = get(prezzi, 'children.0.children', null);

                if (priceProps) {
                    item.children = item.children.concat(priceProps);
                }

                const linee = find(item.children, { property: 'linee' });

                if (linee && linee.children.length > 0) {
                    const lineeArray = linee.children.reduce((list, linea) => {
                        list.push(get(linea, `value.label[${payload.locale}]`, 'n.d.'));

                        return list;
                    }, []);

                    item.children = item.children.concat({
                        property: 'linea',
                        value: {
                            label: {
                                [payload.locale]: lineeArray.join(' / '),
                            },
                        },
                        children: [],
                    });
                }

                const serie = find(item.children, { property: 'serie' });

                if (serie && serie.children.length > 0) {
                    const serieArray = serie.children.reduce((list, singleSerie) => {
                        list.push(get(singleSerie, `value.label[${payload.locale}]`, 'n.d.'));

                        return list;
                    }, []);

                    item.children = item.children.concat({
                        property: 'single_serie',
                        value: {
                            label: {
                                [payload.locale]: serieArray.join(' / '),
                            },
                        },
                        children: [],
                    });
                }

                return item;
            });
        }
        // const data = res.data.results.map(item => {
        //     const properties = item.children.reduce((obj, property) => {
        //         obj[property.property] = {
        //             value: property.value,
        //             children: property.children
        //         };

        //         return obj;
        //     }, {});

        //     return {
        //         id: item.id,
        //         ...item.metadata,
        //         ...properties
        //     };
        // });

        // res.data.results = data;
        res.data.meta = {
            total: res.data.total > 10000 ? 10000 : res.data.total,
            serverTotal: res.data.total,
        };

        // console.log(res);

        resolve(res);
    });
}

export function fetchItem(id) {
    return client.get(`/product/${id}?property_format=code`);
}

export function fetchItemByBrandAndCode(brand, code) {
    return client.get(`/product/${brand}/${code}?property_format=code`);
}

export function removeItem(id) {
    console.log('removeItem', id);
    return client.delete(`/product/${id}`);
}

export function getAllProductProperties() {
    return client.get('property/all');
}

// export function saveDataCategoriesPreferences(preferences) {
//     return clientFake.post('/preferences', {
//         preferences
//     });
// }

// Auth routes
export function login(data) {
    return client.post('/login', data);
}

export function refresh(refresh_token) {
    return client.post(
        '/refresh',
        {},
        {
            headers: {
                Authorization: `Bearer ${refresh_token}`,
            },
        }
    );
}

export function signup(data) {
    return client.post('/signup', data);
}

let provinceCache = null;
let titoliCache = null;
let attivitaCache = null;

// Gestione utenti
export function getProvince() {
    if (provinceCache !== null) {
        return new Promise((resolve) => {
            return resolve(provinceCache);
        });
    }

    const response = client.get('/province');

    response.then(({ data }) => {
        if (data !== null) {
            provinceCache = { data };
        }
    });

    return response;
}

export function getTitoli() {
    if (titoliCache !== null) {
        return new Promise((resolve) => {
            return resolve(titoliCache);
        });
    }

    const response = client.get('/titoli');

    response.then(({ data }) => {
        if (data !== null) {
            titoliCache = { data };
        }
    });

    return response;
}

export function getAttivita() {
    if (attivitaCache !== null) {
        return new Promise((resolve) => {
            return resolve(attivitaCache);
        });
    }

    const response = client.get('/attivita');

    response.then(({ data }) => {
        if (data !== null) {
            attivitaCache = { data };
        }
    });

    return response;
}

export function getProvinceUtenti(params = {}) {
    return client.post('/utenti/province', params);
}

export function getAttivitaUtenti(params = {}) {
    return client.post('/utenti/attivita', params);
}

export function getUtente(id) {
    return client.get(`/utente/${id}`);
}

export function fetchUtenti(params = {}) {
    return client.post('/utenti', params);
}

export function updateUtente(id, data) {
    return client.patch(`/utente/${id}`, data);
}

export function enableUtente(id) {
    return client.patch(`/utente/${id}/enable`);
}

export function toggleLockUtente(id) {
    return client.patch(`/utente/${id}/change-lock`);
}

export function forgotPassword(data) {
    return client.post('/forgot-password', data);
}

export function resetPassword(data, token) {
    return client.post('/reset-password', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
}

export function changePassword(id, data) {
    return client.patch(`/utente/${id}/change-password`, data);
}

export function deleteUtente(id) {
    return client.delete(`/utente/${id}/delete`);
}

export function getLocales() {
    return client.get('/locales');
}

export function changeUserLocale(userId, locale) {
    return client.patch(`/utente/${userId}/change-locale`, {
        locale,
    });
}

export function changeUserPreferences(userId, preferences) {
    return client.patch(`/utente/${userId}/change-preferences`, preferences);
}

export function updateUser(userId, payload) {
    return client.patch(`/users/${userId}`, payload);
}

// Articolo pubblico

export function showArticolo(brand, article) {
    return client.get(`/client/product/${brand}/${article}?property_format=code`);
}

export function getPublicProductInfoMap() {
    return client.get('/client/property/all');
}

export function fetchPublicEtimClassDetails(id) {
    return client.get(`/client/etim/etim_class/${id}`);
}

// Collezioni

export function fetchCollections(userId, params = { per_page: 999 }) {
    return client.post(`/utente/${userId}/collezioni`, params);
}

export function createCollection(userId, data) {
    return client.post(`/utente/${userId}/collezione`, data);
}

export function updateCollection(userId, id, data) {
    return client.patch(`/utente/${userId}/collezione/${id}`, data);
}

export function deleteCollection(userId, id) {
    return client.delete(`/utente/${userId}/collezione/${id}/delete`);
}

export function dupeCollection(userId, id) {
    return client.post(`/utente/${userId}/collezione/${id}/duplicate`);
}

export function addItemsToCollection(userId, id, items) {
    return client.post(`/utente/${userId}/collezione/${id}/add`, {
        articoli_id: arrify(items),
    });
}

export function removeItemsFromCollection(userId, id, items) {
    return client.post(`/utente/${userId}/collezione/${id}/remove`, {
        articoli_id: arrify(items),
    });
}

export function addFilteredItemsToCollection(userId, id, filters) {
    return client.post(`/utente/${userId}/collezione/${id}/add_filtered`, filters);
}

export function removeFilteredItemsFromCollection(userId, id, filters) {
    return client.post(`/utente/${userId}/collezione/${id}/remove_filtered`, filters);
}

export function getArticlesCollections(userId, filters) {
    return client.post(`/utente/${userId}/collezioni/has_articolo`, filters);
}

// Processi

export function getProcesses() {
    return client.get('/processes');
}

export function removeBrandProducts(brandCode) {
    // return new Promise(resolve => {
    //     setTimeout(() => resolve({}), 2000);
    // });

    return client.post('/process/remove_products/start', { sigla_marca: brandCode });
}

export function fetchAnagrafica() {
    return client.get('/anagrafica');
}

// Gruppi utente

export function getUserGroups() {
    return client.get('/groups');
}

export function createGroup(data) {
    return client.post('/groups', data);
}

export function updateGroup(id, data) {
    return client.patch(`/groups/${id}`, data);
}

export function fetchPreferences() {
    return client.get('/preferences', {
        headers: null,
    });
}

export function setPreference(name, value) {
    return client.post('/preference', {
        name,
        value,
    });
}

export function updatePreference(name, value) {
    return client.patch('/preference', {
        name,
        value,
    });
}

export function getServerVersion() {
    return client.get('/version');
}

export function logAnalytic(data = {}) {
    // {
    // "utente": stringa. valorizzare con nome e cognome utente (se l'utente è loggato, altrimenti con l'IP)
    // "zetcode": stringa. valorizzare sempre. sarà un parametro di sistema, per ora puoi fissare il valore 14070011
    // "brand": valorizzare con la descrizione della marca, quando disponibile (ad esempio nell'apertura di una scheda articolo)
    // "acronym": valorizzare con la sigla della marca, quando disponibile (ad esempio nell'apertura di una scheda articolo)
    // "code": il significato cambia a seconda dell'operazione che si sta eseguendo. Esempi: all'apertura della scheda articolo, o per il download di un asset, memorizzare il codice articolo (codice_articolo)
    // "code_description": il significato cambia a seconda dell'operazione che si sta eseguendo. Esempi: all'apertura della scheda articolo, o per il download di un asset, memorizzare la descrizione dell'articolo (descrizione_articolo)
    // "operation": il significato cambia a seconda dell'operazione che si sta eseguendo. Esempi: all'apertura della scheda articolo valorizzare con "Scheda prodotto", al download di un asset valorizzare con "Download asset"
    // "subject": il significato cambia a seconda dell'operazione che si sta eseguendo. Esempi: per il download di un asset memorizza il nome del file asset.
    // }
    let payload = {
        // zetcode: '14070011',
        ...data,
    };

    return client.post('/log_analytic', payload);
}

export function getAvailableExchangeFormats() {
    return client.get('/available_fds_exports');

    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve([
    //             {
    //                 code: '74',
    //                 label: 'TEST',
    //                 header: [],
    //             },
    //             {
    //                 code: '40',
    //                 label: 'CARICAMENTO LISTINI BMEcatETIM nel Datatpool',
    //                 header: [],
    //             },
    //             {
    //                 code: '72',
    //                 label: 'Lancia Creazione EMDG',
    //                 header: [
    //                     [
    //                         {
    //                             key: 'supplier_name',
    //                             label: 'Fornitore',
    //                             type: 'string',
    //                             value: 'gigi',
    //                         },
    //                         {
    //                             key: 'supplier_gln',
    //                             label: 'Partita IVA',
    //                             type: 'string',
    //                             value: '1234567890123',
    //                         },
    //                         {
    //                             key: 'supplier_duns',
    //                             label: 'Numero DUNS',
    //                             type: 'string',
    //                             value: '123456789',
    //                         },
    //                         {
    //                             key: 'etim_release',
    //                             label: 'Versione Etim',
    //                             type: 'defined_choice',
    //                             value: 'etim-7.0',
    //                             defined_values: 'dynamic,etim-8.0,etim-7.0,etim-6.0',
    //                         },
    //                     ],
    //                 ],
    //             },
    //         ]);
    //     }, 2000);
    // });
}

export function startExport(payload = {}) {
    // console.warn(payload);

    return client.post('/fds_export', payload);

    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve({
    //             code: '72',
    //             status: 'started',
    //         });
    //     }, 2000);
    // });
}

export function getFilteredExports(_, payload = {}) {
    return client.post('/filter/fds_exports', payload);

    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve({
    //             total: 1,
    //             results: [
    //                 {
    //                     id: '0df84f61-71ea-491c-b803-4585dbd863f3',
    //                     code: '72',
    //                     status: 'started',
    //                     info: {
    //                         link: '/logmonitor/indexLogMonitor_detail.php?uuid=65691760-ee7e-4ce9-ac00-ccdc74644f07&idxStart=0&filtro=&startDate=&endDate=&stato=&utente=&figlio=',
    //                         uuid: '65691760-ee7e-4ce9-ac00-ccdc74644f07',
    //                         title: 'Lancia Creazione EMDG',
    //                         detail: '65691760-ee7e-4ce9-ac00-ccdc74644f07',
    //                         status: 1,
    //                     },
    //                     occurred_at: '2022-11-02 13:02:52',
    //                 },
    //             ],
    //         });
    //     }, 2000);
    // });
}
