import { i18nMark } from '@lingui/react';

export const BRAND_FILTER = 'brand';
export const CATALOG_FILTER = 'catalog';
export const LINE_FILTER = 'line';
export const SERIES_FILTER = 'series';
export const OPTIONALS_FILTER = 'optionals';
export const MODEL_FILTER = 'model';
export const REPLACEMENTS_FILTER = 'replacements';
export const STATUS_FILTER = 'status';
export const FULL_TEXT_FILTER = 'fullText';
export const PRICE_RANGE_FILTER = 'priceRange';
export const LISTINI_FILTER = 'listini';
export const FUORI_CARTACEO_FILTER = 'fuoriCartaceo';
export const ESPORTABILE_PER_CATALOGO_FILTER = 'esportabilePerCatalogo';
// export const OUT_OF_PRODUCTION_FILTER = 'outOfProduction';
// export const GOING_OUT_OF_PRODUCTION_FILTER = 'goingOutOfProduction';

export const FULL_TEXT_SEARCH_MODE_LEGACY = 'legacy';
export const FULL_TEXT_SEARCH_MODE_ELASTIC = 'elastic';
export const FULL_TEXT_SEARCH_MODE_BOTH = 'both';

export const ETIM_CLASSIFICATION = 'etim';
export const IDROLAB_CLASSIFICATION = 'idrolab';
export const DEFAULT_CLASSIFICATION = IDROLAB_CLASSIFICATION;

export const VIEW_TYPE_COMPACT = 'compact';
export const VIEW_TYPE_DETAILED = 'detailed';
export const VIEW_TYPE_BLOCK = 'block';

// export const UM_LABELS = {
//     AE: 'Bombola',
//     AST: 'Astucci',
//     BAS: 'Vaschetta',
//     BE: 'Fascio / Legaccio',
//     BG: 'Busta/Sacchetto',
//     BJ: 'Secchio',
//     BK: 'Cesto',
//     BL: 'Balla',
//     BLC: 'Bilico',
//     BLT: 'Blister',
//     BO: 'Bottiglia',
//     BOB: 'Bobina',
//     BR: 'Barra/Verga',
//     BRI: 'Brick',
//     BX: 'Scatola / Fustino',
//     C62: 'Unità',
//     CA: 'Lattina',
//     CBL: 'Flacone',
//     CF: 'Confezione',
//     CG: 'Fittainer',
//     CH: 'Container',
//     CL: 'Rotolo',
//     CMK: 'Centimetro quadrato',
//     CMQ: 'Centimetro cubo',
//     CMT: 'Centimetro',
//     CO: 'Collo',
//     CQ: 'Cartuccia',
//     CR: 'Cassa',
//     CS: 'Astuccio',
//     CT: 'Cartone',
//     DJ: 'Damigiana non Protetta',
//     DR: 'Fusto',
//     DP: 'Damigiana Protetta',
//     DPE: 'Imballo Espositore',
//     FP: 'Film Termoretraibile',
//     GAB: 'Gabbie',
//     GRM: 'Grammo',
//     HGM: 'Ettogrammo',
//     HUR: 'Ora lavorativa',
//     JR: 'Barattolo / Vasetto',
//     KG: 'Bariletto',
//     KGM: 'Chilogrammo',
//     LTR: 'Litro',
//     MAT: 'Matassa',
//     MBL: 'Mezzo Bilico',
//     MIL: 'Migliaia',
//     MGM: 'Milligrammo',
//     MLT: 'Millilitro',
//     MMO: 'Mezza Motrice',
//     MMT: 'Millimetro',
//     MOT: 'Motrice',
//     MTK: 'Metro quadro',
//     MTQ: 'Metro cubo',
//     MTR: 'Metro',
//     NAR: 'Numero di articoli',
//     NE: 'Nessuna Confezione',
//     PA: 'Pacchetto',
//     PAE: 'Incarto',
//     PF: 'Pallet (sollevato)',
//     PK: 'Pacco',
//     PLT: 'Pallet/Bancale',
//     PL: 'Secchio',
//     PR: 'Coppia',
//     PU: 'Vassoio',
//     RG: 'Anello',
//     PUE: 'Vassoio Filmato',
//     RL: 'Rocchetto in PVC',
//     RO: 'Rullino',
//     SA: 'Sacco',
//     SET: 'Set',
//     ST: 'Foglio',
//     STL: 'Stick',
//     TN: 'Latta',
//     TU: 'Tubo',
//     UUE: 'Rete',
//     Z2: 'Cassetta',
//     Z3: 'Botte'
// };

// export const STATUS_LABELS = {
//     IV000001: 'IN OFFERTA',
//     IV000002: 'NUOVO',
//     IV000003: 'NUOVO PRODOTTO',
//     IV000004: 'VECCHIO PRODOTTO',
//     IV000005: 'RIGENERATO',
//     IV000006: 'USATO',
//     IV000007: 'ALTRO'
// };

export const ATTRIBUTE_TYPE_ALPHANUMERIC = 'alphanumeric';
export const ATTRIBUTE_TYPE_LOGIC = 'logic';
export const ATTRIBUTE_TYPE_NUMERIC = 'numeric';
export const ATTRIBUTE_TYPE_RANGE = 'range';

export const SECTION_TYPE_STANDARD = 'standard';
export const SECTION_TYPE_REPEATABLE = 'repeatable';
export const SECTION_TYPE_FEATURES = 'features';
export const SECTION_TYPE_FILES = 'files';
export const SECTION_TYPE_IMAGES = 'images';
export const SECTION_TYPE_LISTINI = 'listini';

export const SECTION_KEY_IMMAGINI = 'immagine';
export const SECTION_KEY_DISEGNI = 'disegno';
export const SECTION_KEY_VETTORIALI = 'vettoriale';
export const SECTION_KEY_VIDEO = 'videoclip';
export const SECTION_KEY_DOCUMENTI = 'documento';
export const SECTION_KEY_ALTRO = 'altro';
export const SECTION_KEY_CORRELATI = 'articoli_correlati';
export const SECTION_KEY_ID = 'identificazione';
export const SECTION_KEY_MISURE = 'misure';
export const SECTION_KEY_ORDINE = 'dettagli_ordine';
export const SECTION_KEY_AGGREGAZIONI = 'aggregazioni';
export const SECTION_KEY_VARIE = 'varie';
export const SECTION_KEY_PACKAGING = 'confezionamenti';
export const SECTION_KEY_PRICING = 'prezzi';
export const SECTION_KEY_CARATTERISTICHE = 'caratteristiche';
export const SECTION_KEY_LISTINI = 'listini';

export const ARTICLE_DETAIL_SECTIONS = {
    immagine: {
        key: SECTION_KEY_IMMAGINI,
        label: i18nMark('section:images:and:drawing'),
        shortLabel: i18nMark('section:images:and:drawing:short'),
        classLabel: i18nMark('class:image'),
        order: 11,
        type: SECTION_TYPE_IMAGES,
    },
    disegno: {
        key: SECTION_KEY_DISEGNI,
        label: i18nMark('section:technical:drawing'),
        shortLabel: i18nMark('section:technical:drawing:short'),
        classLabel: i18nMark('class:drawing'),
        order: 12,
        type: SECTION_TYPE_IMAGES,
    },
    vettoriale: {
        key: SECTION_KEY_VETTORIALI,
        label: i18nMark('section:vectorial'),
        shortLabel: i18nMark('section:vectorial:short'),
        classLabel: i18nMark('class:vectorial'),
        order: 13,
        type: SECTION_TYPE_FILES,
    },
    videoclip: {
        key: SECTION_KEY_VIDEO,
        label: i18nMark('section:video'),
        shortLabel: i18nMark('section:video:short'),
        classLabel: i18nMark('class:video'),
        order: 15,
        type: SECTION_TYPE_FILES,
    },
    documento: {
        key: SECTION_KEY_DOCUMENTI,
        label: i18nMark('section:documents'),
        shortLabel: i18nMark('section:documents:short'),
        classLabel: i18nMark('class:document'),
        order: 14,
        type: SECTION_TYPE_FILES,
    },
    identificazione: {
        key: SECTION_KEY_ID,
        label: i18nMark('section:article:id'),
        shortLabel: i18nMark('section:article:id:short'),
        order: 1,
        type: SECTION_TYPE_STANDARD,
    },
    misure: {
        key: SECTION_KEY_MISURE,
        label: i18nMark('section:measure'),
        shortLabel: i18nMark('section:measure:short'),
        order: 2,
        field_list: [],
        type: SECTION_TYPE_STANDARD,
    },
    dettagli_ordine: {
        key: SECTION_KEY_ORDINE,
        label: i18nMark('section:order:details'),
        shortLabel: i18nMark('section:order:details:short'),
        order: 3,
        type: SECTION_TYPE_STANDARD,
    },
    aggregazioni: {
        key: SECTION_KEY_AGGREGAZIONI,
        label: i18nMark('section:groups'),
        shortLabel: i18nMark('section:groups:short'),
        order: 4,
        type: SECTION_TYPE_STANDARD,
    },
    varie: {
        key: SECTION_KEY_VARIE,
        label: i18nMark('section:various'),
        shortLabel: i18nMark('section:various:short'),
        order: 5,
        type: SECTION_TYPE_STANDARD,
    },
    packaging: {
        key: SECTION_KEY_PACKAGING,
        label: i18nMark('section:packaging'),
        shortLabel: i18nMark('section:packaging:short'),
        order: 6,
        type: SECTION_TYPE_REPEATABLE,
    },
    listini: {
        key: SECTION_KEY_LISTINI,
        label: i18nMark('listini'),
        shortLabel: i18nMark('listini'),
        order: 7,
        type: SECTION_TYPE_LISTINI,
    },
    pricing: {
        key: SECTION_KEY_PRICING,
        label: i18nMark('section:prices'),
        shortLabel: i18nMark('section:prices:short'),
        order: 8,
        type: SECTION_TYPE_REPEATABLE,
    },
    caratteristiche: {
        key: SECTION_KEY_CARATTERISTICHE,
        label: i18nMark('section:features'),
        shortLabel: i18nMark('section:features:short'),
        order: 9,
        isFeatures: true,
        type: SECTION_TYPE_FEATURES,
    },
    [SECTION_KEY_CORRELATI]: {
        key: SECTION_KEY_CORRELATI,
        label: i18nMark('section:related:articles'),
        shortLabel: i18nMark('section:related:articles:short'),
        order: 10,
        type: SECTION_TYPE_REPEATABLE,
    },
    altro: {
        key: SECTION_KEY_ALTRO,
        label: i18nMark('section:others'),
        shortLabel: i18nMark('section:others:short'),
        classLabel: i18nMark('class:asset'),
        order: 16,
        type: SECTION_TYPE_FILES,
    },
};

export const BRAND_PROCESS_IMPORT_PRODUCTS = i18nMark('import_products');
export const BRAND_PROCESS_REMOVE_PRODUCTS = i18nMark('remove_products');
export const BRAND_PROCESS_REMOVE_INDEXED_PRODUCTS = i18nMark('remove_indexed_products');
export const BRAND_PROCESS_REPROJECT_PRODUCTS = i18nMark('reproject_products');
export const BRAND_PROCESS_INDEX_PRODUCTS = 'index_products';
export const BRAND_PROCESS_COMPLETED = i18nMark('completed');
export const BRAND_PROCESS_IMPORT_FAILED = i18nMark('failed');
export const BRAND_PROCESS_IMPORT_PATCH = i18nMark('patch_products');
export const BRAND_PROCESS_EXPORT_PRODUCTS = i18nMark('export_products');

export const EXTRA_CATEGORIES_BLACKLIST_KEYS = [
    'id',
    'metadata',
    'assets',
    'old_assets',
    'null',
    'etim',
];
