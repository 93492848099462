import React, { useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { withI18n, Trans, i18nMark } from '@lingui/react';

import SelectField from './form/SelectField';
import InputField from './form/InputField';
import SubmitButton from './form/SubmitButton';
// import clsx from 'classnames';

export function ExportHeaderModal({ onClose, exchangeFormat, onSubmit }) {
    const onSubmitForm = (data) => {
        onSubmit(data, true);

        onClose();
    };

    const initialValues = useMemo(() => {
        return exchangeFormat.header[0].reduce((values, h) => {
            if (h.value) {
                if (h.type === 'defined_choice') {
                    values[h.key] = { value: h.value, label: h.value };
                } else {
                    values[h.key] = h.value;
                }
            }

            return values;
        }, {});
    }, [exchangeFormat.code]);

    // console.log(initialValues);

    return (
        <div className="modal active" id="export-header-modal">
            <span className="modal-overlay" aria-label="Close" />
            <div className="modal-container" style={{ maxWidth: '800px' }}>
                <div className="modal-header">
                    <div className="modal-title text-center h5">
                        Parametri {exchangeFormat.label}
                        <span
                            className="btn btn-clear float-right"
                            aria-label="Close"
                            onClick={onClose}
                        />
                    </div>
                </div>
                <div className="modal-body min-h-[50vh]" id="user-details-modal">
                    <div className="content">
                        <Form
                            onSubmit={onSubmitForm}
                            //  validate={validate}
                            initialValues={initialValues}
                            render={({
                                handleSubmit,
                                pristine,
                                hasValidationErrors,
                                submitError,
                                submitting,
                                errors,
                                touched,
                                ...rest
                            }) => {
                                // console.warn(rest);

                                return (
                                    <form
                                        // ref={(form) => (this.form = form)}
                                        onSubmit={handleSubmit}
                                        className="form-vertical text-primary"
                                    >
                                        <div className="grid grid-cols-2 gap-2">
                                            {exchangeFormat.header[0].map((h) => {
                                                // console.log(h);

                                                if (h.defined_values) {
                                                    const options = h.defined_values
                                                        .split(',')
                                                        .map((o) => {
                                                            return {
                                                                value: o,
                                                                label: o,
                                                            };
                                                        });

                                                    return (
                                                        <div key={h.key}>
                                                            <Field
                                                                name={h.key}
                                                                disabled={h?.readonly === 'true'}
                                                                component={SelectField}
                                                                options={options}
                                                                layout="vertical"
                                                                placeholder={h.label}
                                                                className="form-input input-lg"
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div key={h.key}>
                                                            <Field
                                                                name={h.key}
                                                                component={InputField}
                                                                disabled={h?.readonly === 'true'}
                                                                layout="vertical"
                                                                placeholder={h.label}
                                                                className="form-input input-lg"
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className="text-right mt-4">
                                                <SubmitButton
                                                    submitting={submitting}
                                                    // disabled={pristine}
                                                    fullWidth={false}
                                                >
                                                    <Trans id="confirm" />
                                                </SubmitButton>
                                            </div>
                                            <div className="text-left mt-4">
                                                <button className="btn btn-lg" onClick={onClose}>
                                                    <Trans id="cancel" />
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withI18n()(ExportHeaderModal);
