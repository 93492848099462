import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContainerDimensions from 'react-container-dimensions';
import { withI18n, Trans } from '@lingui/react';
import Swal from 'sweetalert2';
import Highlighter from 'react-highlight-words';
import clsx from 'classnames';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { NavLink } from 'react-router-dom';
import {
    FaCogs,
    // FaEye,
    FaEdit,
    FaTrash,
    FaCopy,
    FaFileExport,
    // FaDownload
} from 'react-icons/fa';
import cx from 'classnames';

import LimitText from './utils/LimitText';
import Loader from './utils/Loader';
import TableButton from './utils/TableButton';
import SortableTableHeader from './utils/SortableTableHeader';
import Paginator from './Paginator';
import EditCollectionModal from '../containers/EditCollectionModal';
import ArticleTable from '../containers/ArticleTable';
// import { restoreBootstrappedState } from '../actions/appActions';
import { setCollectionViewActive, toggleEngageCollection } from '../actions/collectionsActions';
import { resetItems } from '../actions/itemsActions';

export class CollectionsList extends Component {
    state = {
        showEditCollectionModal: false,
        currentCollectionId: null,
    };

    componentDidMount() {
        this.props.dispatch(setCollectionViewActive(true));
        this.props.fetchCollections();
        this.props.dispatch(resetItems());
    }

    componentWillUnmount() {
        // this.props.dispatch(restoreBootstrappedState());
        this.props.dispatch(setCollectionViewActive(false));
        this.props.dispatch(resetItems());
        this.props.dispatch(toggleEngageCollection(this.props.collectionEngagedId));
    }

    showEditCollectionModal = () => {
        this.setState({
            showEditCollectionModal: true,
        });
    };

    editCollection = (id) => {
        this.setState({
            currentCollectionId: id,
            showEditCollectionModal: true,
        });
    };

    handleCloseModal = () => {
        this.setState({
            currentCollectionId: null,
            showEditCollectionModal: false,
        });
    };

    handleDeleteCollection = (id) => {
        const { i18n } = this.props;

        Swal.fire({
            icon: 'warning',
            text: i18n._('confirm:collection:delete'),
            showCancelButton: true,
            confirmButtonText: i18n._('yes'),
            confirmButtonColor: '#007bff',
            cancelButtonText: i18n._('no'),
        }).then((res) => {
        if (res.value === true) {
                this.props.deleteCollection(id);
            }
        });
    };

    handleDupeCollection = (id) => {
        const { i18n } = this.props;

        const collection = this.getCollectionById(id);

        let collectionName = `<strong>${collection.descrizione}</strong>`;

        if (collection.codice !== null) {
            collectionName = `<strong>${collection.codice}</strong> - ${collectionName}`;
        }

        Swal.fire({
            type: 'warning',
            html: i18n._('collection:confirm:dupe', { name: collectionName }),
            showCancelButton: true,
            confirmButtonText: i18n._('yes'),
            cancelButtonText: i18n._('no'),
        }).then((res) => {
            if (res.value === true) {
                this.props.dupeCollection(id);
            }
        });
    };

    getCollectionById(id) {
        const result = this.props.collections.filter((c) => c.id === id);

        if (result) {
            return result.first();
        }

        return result;
    }

    getCollectionsToDisplay() {
        const { collections } = this.props;

        return sortBy(collections.toJS(), (c) => c.descrizione.toLowerCase());

        // if (collectionEngagedId === null) {
        //     return collections;
        // }

        // const collectionEngaged = this.getCollectionById(collectionEngagedId);

        // // Se non trovo la collezione che mi aspetto ritorno la lista completa per evitare errori
        // if (!collectionEngaged) {
        //     return collections;
        // }

        // return [collectionEngaged];
    }

    handleUpdateFilter = (e) => {
        // console.warn(e);
        // console.warn(e.target.name);

        this.props.updateCollectionFilter(e.target.name, e.target.value);
    };

    getCurrentCollection() {
        return find(this.props.collections.toJS(), { id: this.props.collectionEngagedId });
    }

    render() {
        const { showEditCollectionModal, currentCollectionId } = this.state;

        const {
            filters,
            toggleEngageCollection,
            collectionEngagedId,
            collectionShown,
            i18n,
            isSidebarOpen,
            toggleSidebar,
            enableExportPanel,
            enableExportLists,
        } = this.props;

        const currentCollection = collectionShown ? this.getCurrentCollection() : null;

        // console.log(currentCollection);

        return (
            <div className="grid grid-cols-12">
                <div
                    onClick={toggleSidebar}
                    className={clsx(
                        'lg:hidden z-[1] bg-slate-600 opacity-50 w-full h-screen absolute',
                        {
                            hidden: !isSidebarOpen,
                        }
                    )}
                />
                <ContainerDimensions>
                    {({ width }) => {
                        return (
                            <div
                                style={{ height: 'calc(100vh - 60px)' }}
                                className={clsx(
                                    'px-2 fixed lg:relative w-[80vw] lg:w-full lg:col-span-3 z-10 lg:z-auto pt-1 bg-gray-200 lg:bg-white',
                                    {
                                        hidden: !isSidebarOpen && width < 1024,
                                    }
                                )}
                            >
                                <div className="py-2 border-r h-full overflow-auto">
                                    {showEditCollectionModal && (
                                        <EditCollectionModal
                                            collectionId={currentCollectionId}
                                            onClose={this.handleCloseModal}
                                        />
                                    )}
                                    <h2 className="text-primary px-4 mb-4">Le mie liste</h2>
                                    <h6 className="text-primary px-4">Cerca tra le liste</h6>
                                    <div className="px-4 mb-4">
                                        <input
                                            type="text"
                                            className="form-input input-md"
                                            value={filters.description}
                                            name="description"
                                            onChange={this.handleUpdateFilter}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        {this.getCollectionsToDisplay().map((c) => (
                                            <div
                                                key={c.id}
                                                className={clsx(
                                                    'py-2 border-y px-4 cursor-pointer hover:bg-blue-200',
                                                    {
                                                        'bg-blue-200': collectionEngagedId === c.id,
                                                    }
                                                )}
                                                onClick={() => toggleEngageCollection(c.id)}
                                            >
                                                <LimitText
                                                    text={c.descrizione}
                                                    limit={50}
                                                    highlight={filters.description}
                                                />{' '}
                                                ({c.articoli_number} articoli)
                                            </div>
                                        ))}
                                    </div>
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.showEditCollectionModal}
                                        >
                                            Nuova lista
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </ContainerDimensions>
                <div className="col-span-12 lg:col-span-9">
                    <div className="grid grid-cols-12 gap-1 px-1 lg:px-3 mt-1">
                        {currentCollection && (
                            <div className="col-span-12 lg:col-span-8">
                                <h2 className="text-primary whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    {currentCollection.descrizione}
                                </h2>
                            </div>
                        )}
                        <div className="lg:hidden col-span-8 px-1 lg:px-3">
                            <button className="btn" onClick={toggleSidebar}>
                                <i
                                    className={cx('icon', {
                                        'icon-menu': !isSidebarOpen,
                                        'icon-cross': isSidebarOpen,
                                    })}
                                ></i>
                            </button>
                        </div>
                        {currentCollection && (
                            <div className="col-span-4 text-right">
                                {enableExportPanel && enableExportLists && (
                                    <NavLink
                                        to={`/app/exports?type=Collezione&collectionId=${currentCollection.id}`}
                                        className="btn relative ml-2 tooltip tooltip-left cursor-pointer"
                                        data-tooltip="Esporta Lista"
                                        style={{ marginRight: '4px' }}
                                    >
                                        <FaFileExport style={{ marginTop: '4px' }} />
                                    </NavLink>
                                )}
                                <button
                                    className="btn mr-1 tooltip tooltip-left"
                                    data-tooltip={i18n._('collection:edit')}
                                    onClick={() => this.editCollection(currentCollection.id)}
                                >
                                    <FaEdit />
                                </button>
                                <button
                                    className="btn tooltip tooltip-left"
                                    data-tooltip={i18n._('collection:delete')}
                                    onClick={() =>
                                        this.handleDeleteCollection(currentCollection.id)
                                    }
                                >
                                    <FaTrash />
                                </button>
                            </div>
                        )}
                    </div>
                    <ArticleTable />
                </div>
            </div>
        );
    }

    renderOld() {
        const { showEditCollectionModal, currentCollectionId } = this.state;

        const {
            isFetching,
            toggleEngageCollection,
            // toggleShownCollection,
            collectionEngagedId,
            // collectionShown,
            sorting,
            sortBy,
            pages,
            currentPage,
            filters,
            total,
            total_unfiltered,
            i18n,
        } = this.props;

        if (isFetching) {
            return <Loader />;
        }

        // console.warn(this.props);

        return (
            <div className="columns">
                {showEditCollectionModal && (
                    <EditCollectionModal
                        collectionId={currentCollectionId}
                        onClose={this.handleCloseModal}
                    />
                )}
                <table className="table table-dense">
                    <thead>
                        <tr>
                            <SortableTableHeader
                                headerStyle={{ width: '25%' }}
                                attr="codice"
                                disabled={collectionEngagedId !== null}
                                onClick={sortBy}
                                sorting={sorting}
                            >
                                <Trans id="code" />
                            </SortableTableHeader>
                            <SortableTableHeader
                                attr="descrizione"
                                disabled={collectionEngagedId !== null}
                                onClick={sortBy}
                                sorting={sorting}
                            >
                                <Trans id="collection:description" />
                            </SortableTableHeader>
                            <th style={{ width: '200px' }}>
                                {collectionEngagedId === null && (
                                    <Paginator
                                        pages={pages}
                                        currentPage={currentPage}
                                        onPageChange={this.props.changePage}
                                        containerClassName="pagination pagination-compact"
                                    />
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    className="form-input input-md"
                                    value={filters.code}
                                    name="code"
                                    onChange={this.handleUpdateFilter}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-input input-md"
                                    value={filters.description}
                                    name="description"
                                    onChange={this.handleUpdateFilter}
                                />
                            </td>
                            <td>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={this.showEditCollectionModal}
                                >
                                    <i className="icon icon-plus" />
                                </button>
                            </td>
                        </tr>
                        {total === 0 && total_unfiltered > 0 && (
                            <tr>
                                <td colSpan="99">
                                    <div className="empty empty-short">
                                        <div className="empty-icon">
                                            <i className="icon icon-search" />
                                        </div>
                                        <p className="empty-title h5">
                                            <Trans id="attention" />
                                        </p>
                                        <p className="empty-subtitle">
                                            <Trans id="collection:not:found" />
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        )}
                        {this.getCollectionsToDisplay().map((c) => (
                            <tr key={c.id}>
                                <td>
                                    <LimitText
                                        text={c.codice}
                                        limit={30}
                                        highlight={filters.code}
                                    />
                                </td>
                                <td>
                                    <span
                                        className={c.note !== null ? 'tooltip' : ''}
                                        data-tooltip={c.note}
                                    >
                                        <Highlighter
                                            searchWords={[filters.description]}
                                            textToHighlight={c.descrizione}
                                        />
                                    </span>{' '}
                                    <span className="label label-rounded label-primary">
                                        {c.articoli_number}
                                    </span>
                                </td>
                                <td colSpan={2}>
                                    <TableButton
                                        tooltip={i18n._('collection:engage')}
                                        active={collectionEngagedId === c.id}
                                    >
                                        <FaCogs onClick={() => toggleEngageCollection(c.id)} />
                                    </TableButton>
                                    {/* <TableButton
                                        tooltip={i18n._('collection:view')}
                                        active={collectionShown === c.id}
                                    >
                                        <FaEye onClick={() => toggleShownCollection(c.id)} />
                                    </TableButton> */}
                                    <TableButton tooltip={i18n._('collection:edit')}>
                                        <FaEdit onClick={() => this.editCollection(c.id)} />
                                    </TableButton>
                                    <TableButton tooltip={i18n._('collection:delete')}>
                                        <FaTrash
                                            onClick={() => this.handleDeleteCollection(c.id)}
                                        />
                                    </TableButton>
                                    <TableButton tooltip={i18n._('collection:dupe')}>
                                        <FaCopy onClick={() => this.handleDupeCollection(c.id)} />
                                    </TableButton>
                                    <TableButton tooltip={'Download CSV'}>
                                        <FaFileCsv onClick={() => {}} />
                                    </TableButton>
                                    {/* <TableButton tooltip="Esporta la collezione">
                                        <FaDownload />
                                    </TableButton> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

CollectionsList.propTypes = {
    isFetching: PropTypes.bool,
    collections: PropTypes.object,
    collectionEngagedId: PropTypes.string,
    collectionShown: PropTypes.string,
    toggleEngageCollection: PropTypes.func,
    enableExportPanel: PropTypes.bool,
};

export default withI18n()(CollectionsList);
