import React, { Component, Fragment } from 'react';
import { Trans, i18nMark, withI18n } from '@lingui/react';
// import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import debounce from 'lodash/debounce';

import MatchWhenRole from './routing/MatchWhenRole';
import ArticlePagination from './ArticlePagination';
import UserDetailsModal from './UserDetailsModal';
import SortableTableHeader from './utils/SortableTableHeader';
import Loader from './utils/Loader';
import { enableUtente, toggleLockUtente } from '../api';
import { actions /*, updateUserData*/ } from '../actions/usersActions';
// import SelectProvince from './select/SelectProvince';
// import SelectAttivita from './select/SelectAttivita';
import SelectGroup from './select/SelectGroup';
import { getLanguageLabelFromLocale } from '../intl-helpers';

const paginationUsersString = i18nMark('pagination:users');

export class UsersList extends Component {
    constructor(props) {
        super(props);

        this.updateFilter = debounce(this.updateFilter, 500);
    }

    state = {
        showModal: false,
        currentUserId: null,
    };

    componentDidMount() {
        this.props.dispatch(actions.fetchUsers());
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFetching === true && this.props.isFetching === false) {
            setTimeout(() => {
                this.filterInput.focus();
            }, 0);
        }

        if (prevProps.filters.q !== this.props.filters.q) {
            this.filterInput.value = this.props.filters.q;
        }
    }

    componentWillUnmount() {
        this.props.resetFilters();
    }

    updateFilter = (q) => {
        this.props.dispatch(actions.setUsersFilter('q', q));
    };

    handleEnable = async (id) => {
        // TODO: spostare in una saga?
        const res = await enableUtente(id);

        // TODO: procedere solo se nel payload di ritorno ho conferma del successo dell'operazione
        this.props.enableUser(id);
        this.props.updateUserData(id, res.data);
    };

    handleToggleLockUtente = async (id) => {
        // TODO: spostare in una saga?
        const res = await toggleLockUtente(id);

        // TODO: procedere solo se nel payload di ritorno ho conferma del successo dell'operazione
        this.props.toggleLock(id);
        this.props.updateUserData(id, res.data);
    };

    handleUpdateUserGroup = (id, data) => {
        // console.log(id, data);
        this.props.updateUserData(id, data);
    };

    handleShowDetails(id) {
        this.setState({
            showModal: true,
            currentUserId: id,
        });
    }

    closeDetails = () => {
        this.setState({
            showModal: false,
            currentUserId: null,
        });
    };

    getCurrentUser() {
        let user = this.props.users.find((u) => u.id === this.state.currentUserId);

        if (user) {
            user = user.toJS();

            user.group_id = user.group_id
                ? { value: user.group_id, label: this.getGroupLabelById(user.group_id) }
                : null;
        }

        return user;
    }

    getGroupLabelById(groupId) {
        const group = this.props.userGroups[groupId];

        if (!group) {
            return groupId;
        }

        return group.label;
    }

    renderRows() {
        if (this.props.users.size === 0) {
            return (
                <tr>
                    <td colSpan="999" className="bg-gray">
                        <div className="empty">
                            <div className="empty-icon">
                                <i className="icon icon-search" />
                            </div>
                            <p className="empty-title h5">
                                {/* <Trans
                                    id="article:count"
                                    values={{ count: 0 }}
                                /> */}
                                <Trans id="No user found" />
                            </p>
                            <p className="empty-subtitle">
                                <Trans id="Use filters to re-search" />
                            </p>
                        </div>
                    </td>
                </tr>
            );
        }

        return this.props.users.map((u) => {
            return (
                <tr key={u.id}>
                    <td style={{ width: '10%' }}>
                        <Highlighter
                            searchWords={[this.props.filters.q]}
                            textToHighlight={u.nome}
                        />
                    </td>
                    <td style={{ width: '10%' }}>
                        <Highlighter
                            searchWords={[this.props.filters.q]}
                            textToHighlight={u.cognome}
                        />
                    </td>
                    <td style={{ width: '10%' }}>
                        <Highlighter
                            searchWords={[this.props.filters.q]}
                            textToHighlight={u.email}
                        />
                    </td>
                    <td style={{ width: '10%' }}>
                        <Highlighter
                            searchWords={[this.props.filters.q]}
                            textToHighlight={u.ragione_sociale === '' ? '-' : u.ragione_sociale}
                        />
                    </td>
                    <td style={{ width: '10%' }}>{this.getGroupLabelById(u.group_id)}</td>
                    {/* <td style={{ width: '10%' }}>{u.attivita}</td> */}
                    {/* <td style={{ width: '8%' }}>{u.nome_provincia}</td> */}
                    <td style={{ width: '8%' }}>{getLanguageLabelFromLocale(u.locale)}</td>
                    <td style={{ width: '8%' }}>
                        <div className="form-group">
                            <label className="form-switch">
                                <input
                                    type="checkbox"
                                    checked={u.enabled}
                                    onChange={() => this.handleEnable(u.id)}
                                    disabled={u.enabled}
                                />
                                <i className="form-icon" />
                            </label>
                        </div>
                    </td>
                    <td style={{ width: '8%' }}>
                        <div className="form-group">
                            <label className="form-switch">
                                <input
                                    type="checkbox"
                                    checked={u.locked}
                                    onChange={() => this.handleToggleLockUtente(u.id)}
                                />
                                <i className="form-icon" />
                            </label>
                        </div>
                    </td>
                    <td className="text-center" style={{ width: '8%' }}>
                        <span
                            onClick={() => this.handleShowDetails(u.id)}
                            className="c-hand text-primary"
                        >
                            <i className="icon icon-search" />
                        </span>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const {
            isFetching,
            // users,
            currentPage,
            pages,
            total,
            filters,
            onPageChange,
            sortBy,
            sorting,
            i18n,
        } = this.props;

        const { showModal } = this.state;

        const yes = i18n._('yes');
        const no = i18n._('no');

        return (
            <Fragment>
                {showModal && (
                    <UserDetailsModal
                        onClose={this.closeDetails}
                        user={this.getCurrentUser()}
                        onToggleLockUtente={this.handleToggleLockUtente}
                        onEnableUtente={this.handleEnable}
                        onDeleteUtente={this.props.deleteUser}
                        onUpdateUserGroup={this.handleUpdateUserGroup}
                    />
                )}
                <div className="grid grid-cols-12 gap-2 px-1">
                    <div className="col-span-full text-center mt-2">
                        <h2 className="text-primary">
                            <Trans id="User management" />
                        </h2>
                    </div>
                    <div className="xl:col-span-4 col-span-6">
                        <input
                            ref={(ref) => (this.filterInput = ref)}
                            type="text"
                            className="form-input"
                            placeholder={i18n._('filter:by')} //"Filtra per Nome, Cognome, E-mail, Azienda..."
                            defaultValue={filters.q}
                            onChange={(e) => this.updateFilter(e.target.value)}
                        />
                    </div>
                    <div className="xl:col-span-2 col-span-6">
                        <SelectGroup
                            selectedItem={filters.group_id}
                            placeholder={i18n._('filter:group')}
                            inputClassName="form-input"
                            filter={filters.q}
                            onChange={(option) =>
                                this.props.dispatch(actions.setUsersFilter('group_id', option))
                            }
                        />
                    </div>
                    {/* <div className="xl:col-span-1 col-span-6">
                        <SelectAttivita
                            selectedItem={filters.attivita}
                            placeholder={i18n._('filter:activity')}
                            inputClassName="form-input"
                            type="user"
                            filter={filters.q}
                            onChange={(option) =>
                                this.props.dispatch(actions.setUsersFilter('attivita', option))
                            }
                        />
                    </div>
                    <div className="xl:col-span-2 col-span-6">
                        <SelectProvince
                            selectedItem={filters.provincia}
                            placeholder={i18n._('filter:province')}
                            inputClassName="form-input"
                            type="user"
                            filter={filters.q}
                            onChange={(option) =>
                                this.props.dispatch(actions.setUsersFilter('provincia', option))
                            }
                        />
                    </div> */}
                    <div className="xl:col-span-1 col-span-4">
                        <select
                            className="form-select"
                            value={filters.enabled}
                            onChange={(e) =>
                                this.props.dispatch(
                                    actions.setUsersFilter('enabled', e.target.value)
                                )
                            }
                        >
                            <option value="">{i18n._('enabled')}</option>
                            <option value="1">{yes}</option>
                            <option value="0">{no}</option>
                        </select>
                    </div>
                    <div className="xl:col-span-1 col-span-4">
                        <select
                            className="form-select"
                            value={filters.locked}
                            onChange={(e) =>
                                this.props.dispatch(
                                    actions.setUsersFilter('locked', e.target.value)
                                )
                            }
                        >
                            <option value="">{i18n._('blocked')}</option>
                            <option value="1">{yes}</option>
                            <option value="0">{no}</option>
                        </select>
                    </div>
                    <div className="xl:col-span-1 col-span-4">
                        <button
                            className="btn btn-primary btn-block"
                            onClick={this.props.resetFilters}
                        >
                            <Trans id="reset" />
                        </button>
                    </div>
                    <div className="col-span-full">
                        <table className="table table-striped table-hover table-dense table-users">
                            <thead>
                                <tr>
                                    <SortableTableHeader
                                        attr="nome"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="name" />
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        attr="cognome"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="surname" />
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        attr="email"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        Email
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        attr="ragione_sociale"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="company" />
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        attr="group_id"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="group" />
                                    </SortableTableHeader>
                                    {/* <SortableTableHeader
                                        attr="attivita"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="activity" />
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        attr="sigla_provincia"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="province" />
                                    </SortableTableHeader> */}
                                    <th>
                                        <Trans id="language" />
                                    </th>
                                    <SortableTableHeader
                                        attr="enabled"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="enabled" />
                                    </SortableTableHeader>
                                    <SortableTableHeader
                                        attr="locked"
                                        onClick={sortBy}
                                        sorting={sorting}
                                    >
                                        <Trans id="blocked" />
                                    </SortableTableHeader>
                                    <th>
                                        <Trans id="detail" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isFetching ? (
                                    <tr>
                                        <td colSpan="999">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : (
                                    this.renderRows()
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="grid grid-cols-2 mx-2">
                    {/* <div className="my-2">
                        <Link className="text-primary" to="/app">
                            <i className="icon icon-back" /> <Trans id="back:to:article:list" />
                        </Link>
                    </div> */}
                    <div>
                        <ArticlePagination
                            onPageChange={onPageChange}
                            currentPage={currentPage}
                            pages={pages}
                            total={total}
                            itemTranslationKey={paginationUsersString}
                            layout="compact"
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const UsersListIntl = withI18n()(UsersList);

export default MatchWhenRole(UsersListIntl, 'admin');
