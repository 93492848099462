import { FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';

import Tooltip from './utils/Tooltip';
import { deleteItems } from '../actions/itemsActions';
import { selectors as itemsSelectors } from '../reducers/itemsReducer';

function DeleteArticleBtnComponent({ code, id, dispatch, i18n, selectedItems }) {
    const isSingle = typeof id !== 'undefined' && typeof code !== 'undefined';

    const confirmText = isSingle
        ? i18n._(`Vuoi eliminare articolo selezionato "${code}"?`)
        : i18n._('Vuoi eliminare gli articoli selezionati?');

    // console.log(selectedItems)

    return (
        <span
            className="cursor-pointer text-primary"
            style={{ position: 'relative', top: '-1px' }}
            onClick={() => {
                if (isSingle === false && selectedItems.length === 0) {
                    alert('Seleziona almeno un articolo');
                    return null;
                }

                Swal.fire({
                    html: i18n._(confirmText),
                    showCancelButton: true,
                    confirmButtonText: i18n._('yes'),
                    cancelButtonText: i18n._('no'),
                    icon: 'warning',
                }).then((res) => {
                    if (res.value === true) {
                        const itemsToDelete = isSingle ? [id] : selectedItems;
                        dispatch(deleteItems(itemsToDelete));
                    }
                });
            }}
        >
            <Tooltip content={'Elimina articolo selezionato'} position="top">
                <FaTrash size={'22px'} />
            </Tooltip>
        </span>
    );
}

function mapStateToProps(state) {
    return {
        selectedItems: itemsSelectors.getSelectedItems(state).toJS(),
    };
}

const DeleteArticleBtnConnected = connect(mapStateToProps)(DeleteArticleBtnComponent);
export const DeleteArticleBtn = withI18n()(DeleteArticleBtnConnected);
